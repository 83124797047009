// @use "./colors";

html {
  //   width: 100%;
  //   max-width: 100%;
  //   min-width: 100%;
  margin: 0;
  padding: 0;
  // box-sizing: border-box;
}
body {
  width: 100%;
  max-width: 100%;
  margin: 0;

  font-family: "Questrial serif";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//     monospace;
// }
