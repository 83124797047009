@use "./colors";

footer {
  border: 1px solid #000000;
  background: black;
  color: white;
  padding-top: 0.75em;
  height: 30%;
  min-height: 3em;
}
