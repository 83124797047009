.mobile-links {
  color: #fff;
  text-decoration: none;
  text-align: center;
  line-height: 3rem;
}

HamburgerMenu {
  max-width: 40%;
  flex: 0 0 50%;
}
