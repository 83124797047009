@use "./colors";

.App {
  // width: 100%;
  // max-width: 100%;
  display: block;
  background: linear-gradient(
    to right,
    rgba(142, 90, 194, 1),
    rgba(142, 90, 194, 0)
  );
}

h1.MuiTypography-h1 {
  font-size: 3rem;
  text-align: center;
}

h2.MuiTypography-h2 {
  font-size: 2rem;
  text-align: center;
}

h3.MuiTypography-h3 {
  font-size: 1.5rem;
  text-align: center;
}

h4.MuiTypography-h4 {
  font-size: 1rem;
  font-weight: 600;
  margin-inline-start: 10%;
  margin-block-start: 1.5em;
}

p.MuiTypography-body1 {
  margin-block: 1em;
  margin-inline: 10%;
}

@media screen and (max-width: 425px) {
  p.MuiTypography-body1 {
    // margin-inline-start: 0em;
  }

  h1.MuiTypography-h1 {
    font-size: 1.75rem;
    margin-inline-start: 0.25em;
  }

  h2.MuiTypography-h2 {
    font-size: 1.5rem;
    margin-block: 0.5em;
  }

  h3.MuiTypography-h3 {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 700px) {
}
// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//   }
// }

.App-link {
}
MenuItem {
  color: colors.$accent;
}
